module.exports = [{
      plugin: require('/Users/diomarimadulara/code/diom.dev/node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"UA-129725667-2"},
    },{
      plugin: require('/Users/diomarimadulara/code/diom.dev/node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('/Users/diomarimadulara/code/diom.dev/node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('/Users/diomarimadulara/code/diom.dev/node_modules/gatsby-plugin-typography/gatsby-browser.js'),
      options: {"plugins":[],"pathToConfigModule":"src/utils/typography"},
    },{
      plugin: require('/Users/diomarimadulara/code/diom.dev/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
